import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
import HelloPop from '@/views/planfir/index.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "about" */ '../views/testpage/sectest.vue')
  },
  {
    path: '/p1',
    name: 'testpage',
    component: () => import(/* webpackChunkName: "about" */ '../views/testpage/sectest.vue')
  },
  {
    path: '/firdetail',
    name: 'firdetail',
    component: () => import(/* webpackChunkName: "about" */ '../views/planfir/detail.vue')
  },
  {
    path: '/firresult',
    name: 'firresult',
    component: () => import(/* webpackChunkName: "about" */ '../views/planfir/result.vue')
  },
  {
    path: '/plansec',
    name: 'plansec',
    component: () => import(/* webpackChunkName: "about" */ '../views/plansec/index.vue')
  },
  {
    path: '/secdetail',
    name: 'secdetail',
    component: () => import(/* webpackChunkName: "about" */ '../views/plansec/detail.vue')
  },
  {
    path: '/p2',
    name: 'testsec',
    component: () => import(/* webpackChunkName: "about" */ '../views/testpage/initdetail.vue')
  },
  {
    path: '/thirdetail',
    name: 'thirdetail',
    component: () => import(/* webpackChunkName: "about" */ '../views/testpage/detail.vue')
  },
  {
    path: '/result',
    name: 'result',
    component: () => import(/* webpackChunkName: "about" */ '../views/result/index.vue')
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
