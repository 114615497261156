import { request } from '@/utils/request'
import { api } from "./baseApi.js"
// import http from './http'
// const BaseUrl = "http://localhost:8081/";
export function getProjectInfo(params) {
  return request({
    // url: `${BaseUrl}/api/user/updateInfo`,
    url: `${api}/advertise/api/project`,
    methods: 'get',
    params
  })
}

export function getMsg(params) {
  return request({
    // url: `${BaseUrl}/api/user/updateInfo`,
    url: `${api}/advertise/api/sendSmg`,
    methods: 'get',
    params
  })
}

export function buyProject(params) {
  return request({
    // url: `${BaseUrl}/api/user/updateInfo`,
    url: `${api}/advertise/api/buyAdvertise`,
    methods: 'get',
    params
  })
}

// export function updateUserInfo(data) {
//   return service({
//     // url: `${BaseUrl}/api/user/updateInfo`,
//     url: `${api}/api/user/updateInfo`,
//     methods: 'post',
//     data
//   })
// }

export function getPoint(params) {
  return request({
      url: `${api}/advertise/api/newBurialPoint`,
    methods: 'get',
    params
  })
}