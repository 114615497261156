import axios from 'axios'
import router from "@/router";
export function request(config) {
  // 创建axios的实例
  const instance = axios.create({
    // baseURL: 'http://localhost:8081/',
    timeout: 10000
  })
  // 请求拦截器配置
  instance.interceptors.request.use(res => {
    config.headers = {
      //传json数据格式给后端，如果后端是这个接收格式，配置如下（可不写，因为axios默认将 JavaScript 对象序列化为 json格式）
      'Content-Type': 'application/json',
      /*
      //数据会以普通表单形式（键值对，但不支持文件）发送到后端,如果后端是这个接收格式，配置如下
      'Content-Type':'application/x-www-form-urlencoded'
      //一般上传文件用此类型，数据是表单上传文件，配置如下（ps一般这种数据类型将在具体的请求接口中配置，而不在此统一封装，后面将介绍）
      'Content-Type':'multipart/form-data'
      */
      //其他自定义的请求头也可写在这里，比如常见的请求头中携带tooken
    }
    return res
  }, error => {
    console.log(error)
    return Promise.error(error)
  }
  )
  // 响应拦截器配置
  instance.interceptors.response.use(response => {
    return response.data
  }, error => {
    switch (error.response.status) {
      case 401:
        console.log("无权访问")
        router.push({ path: '/' })
        break
      case 404:
        console.log("404啦")
        router.push({ path: '/' })
        break
      default:
        return Promise.reject(error)
    }
    return Promise.reject(error)
  })
  // 发送真正的网络请求
  return instance(config);
}
export default request